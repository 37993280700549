export const configDomain = process.env.VUE_APP_CONFIGDOMAIN;
export const baseDomain = process.env.VUE_APP_BASEDOMAIN;
export const baseEnv = process.env.VUE_APP_BASEENV;
export const baseUrl = `${baseDomain}/${baseEnv}`;

export const formatNumber = function(value) {
  if (!value || isNaN(value)) return '0';
  return Number(value).toLocaleString();
}

export const formatMoney = function(value) {
  if (!value || isNaN(value)) return 'R$ 00,00';

  return (
    'R$ ' +
    Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
}

export default {
  configDomain,
  baseDomain,
  baseEnv,
  baseUrl,
  formatNumber,
  formatMoney
}
