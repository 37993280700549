<template>
  <div
    style="color: #2d3142"
    class="px-3"
  >
    <vs-card>
      <h2 class="mb-4">
        Gestão de indicadores de pesquisa
      </h2>

      <vs-row class="mb-4">
        <vs-col vs-lg="6">
          <p>Selecione o owner:</p>

          <vs-select
            v-model="selectedOwner"
            class="w-50"
          >
            <vs-select-item
              v-for="(owner, i) in owners"
              :key="i"
              :value="owner.id"
              :text="owner.id + ' - ' + owner.nickName"
            />
          </vs-select>
        </vs-col>

        <vs-col
          v-if="selectedOwner"
          vs-lg="6"
        >
          <p>Selecione o Survey:</p>

          <vs-select
            v-model="selectedSurvey"
            class="w-50"
          >
            <vs-select-item
              v-for="(survey, i) in surveys"
              :key="i"
              :value="survey.surveyID"
              :text="survey.surveyID + ' - ' + survey.surveyName"
            />
          </vs-select>
        </vs-col>
      </vs-row>

      <div
        v-if="questionsList.length"
        class="w-100 px-2 py-4"
        style="border: 1px solid #d8d8d8; border-radius: 10px"
      >
        <h3 class="mb-2">
          Perguntas da pesquisa:
        </h3>

        <div class="align-items-center">
          <div class="d-flex flex-wrap align-items-center">
            <vs-chip
              v-for="(question, i) in questionsList"
              :key="i"
              class="w-auto"
              :color="getChipColor(question.id)"
            >
              <vs-tooltip :text="question.description">
                <span style="color: black !important">
                  {{ question.id }}
                </span>
              </vs-tooltip>
            </vs-chip>
          </div>
        </div>
      </div>

      <vs-button
        v-if="questionsList.length"
        class="mt-4"
        @click="generateIndicators"
      >
        GERAR
      </vs-button>
    </vs-card>
  </div>
</template>

<script>
/* Config */
import { baseUrl } from '@/global';

/* Services */
import axios from 'axios';

import Survey from '@/services/survey';
import System from '@/services/system';

const surveyService = new Survey();
const systemService = new System();

export default {
  name: 'SurveyIndicators',
  components: {},
  props: {},
  data: () => ({
    owners: [],
    surveys: [],
    selectedOwner: null,
    selectedSurvey: null,
    surveyStruct: [],
    indicators: [],
  }),
  computed: {
    questionsList() {
      if (!this.surveyStruct && !this.surveyStruct.length) return [];

      let questions = [];
      let types = [3, 4, 5, 9, 12];

      this.surveyStruct.forEach((chapter) => {
        if (chapter.requirements && chapter.requirements.length) {
          chapter.requirements.forEach((requirement) => {
            if (requirement.questions && requirement.questions.length) {
              requirement.questions.forEach((question) => {
                if (types.includes(question.type)) {
                  questions.push({
                    description: question.text,
                    id: question.id,
                  });
                }
              });
            }
          });
        }
      });

      return questions;
    },
    indicatorsList() {
      if (!this.indicators && !this.indicators.length) return [];

      return this.indicators.map((el) => {
        return el.indId;
      });
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    selectedOwner(newValue) {
      this.getSurveys(newValue);
      this.selectedSurvey = null;
      this.surveyStruct = [];
      this.indicators = [];
    },
    selectedSurvey(newValue) {
      if (newValue) {
        this.getSurveyStruct(newValue);
        this.getOwnerIndicators();
      }
    },
  },
  mounted() {
    this.getOwners();
  },
  methods: {
    getOwners() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getOwners()
        .then((res) => {
          this.owners = res.data.data;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    getSurveys(owner) {
      this.$store.dispatch('set_isLoadingActive', true);
      surveyService.getSurveysOwner(owner, this.token)
        .then((res) => {
          this.surveys = res.surveys;
          this.increment++;
          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        });
    },
    getSurveyStruct() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService
        .getSurveyStruct(this.selectedSurvey, this.token)
        .then((res) => {
          this.surveyStruct = res.chapters;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          alert(err);

          this.$store.dispatch('set_isLoadingActive', false);
        });
    },
    getOwnerIndicators() {
      const url = `${baseUrl}/indicators/${this.selectedOwner}`;

      axios
        .get(url)
        .then((res) => {
          this.indicators = res.data.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    generateIndicators() {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/indicators/GenerateSurveyIndicators/${this.selectedSurvey}/${this.selectedOwner}`;

      axios
        .post(url)
        .then(() => {
          this.getOwnerIndicators();
          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        });
    },
    getChipColor(questId) {
      let contains = this.indicatorsList.includes(questId);
      if (contains) return '#6f6';
      return '#F44336';
    },
  },
};
</script>
